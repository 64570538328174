import React from "react"

import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Publication from "../components/publication"
import poster from "../assets/2019_Poster_MarianeSchneider_Kyoto2019.pdf"

const PublicationsPage = ({ data }) => {
	let pubList = [];
	console.log(data.allPublicationsYaml.nodes[0]);
	for(let i = 0; i<data.allPublicationsYaml.nodes[0].publications.length; i++){
		let publication = data.allPublicationsYaml.nodes[0].publications[i];
		let authorList = "";
		for(let j = 0; j < publication.authors.length; j++){
			let author = publication.authors[j];
			authorList += author + ", ";
		}
		let journalString = publication.journal
		+ " " + publication.volume +", " + publication.page
		+ " (" + publication.publicationDate+")";

		pubList.push(
				<li>
					<Publication
					key={publication.doiLink}
					authors={authorList}
					title={publication.title+","}
					journal={journalString}
					link={publication.doiLink}
					preprintLink={publication.preprintLink}
					/>
				</li>
		);
	}
	return (
	<Layout>
		<Container className="mt-5">
			<Row>
				<Col className="text-center">
					<h1>Peer-reviewed publications</h1>
				</Col>
			</Row>
			<Row className="mt-5 justify-content-center">
				<Col xs={12} md={10}>
					<ul className="publicationList">
						{pubList}
					</ul>
				</Col>
			</Row>

			<Row>
				<Col className="text-center">
					<h1>Poster</h1>
				</Col>
			</Row>
			<Row className="mt-5 justify-content-center">
				<Col xs={12} md={10}>
					<p>
						Kyoto University Symposium on Education and Research in Global Environmental
						Studies in Asia, 2019, <strong>Best Poster Presentation Award</strong>.
						 <a className="font-weight-bold" href={poster}> Download</a>
					</p>
				</Col>
			</Row>
		</Container>
	</Layout>
)};

export const query = graphql`
	query {
		allPublicationsYaml{
			nodes {
				publications {
					authors
					journal
					doiLink
					page
					preprintLink
					publicationDate(formatString: "YYYY")
					title
					volume
				}
			}
		}
	}
`

export default PublicationsPage
