import PropTypes from "prop-types"
import React from "react"

const Publication = ({ authors, title, journal, link, preprintLink }) => {
	let linkPart; 
	if (link != null && preprintLink == null) {
		linkPart = <p><a href={link}>Paper</a></p> 
	} else if (link != null && preprintLink != null) {
		linkPart = (
			<p>
				<a href={link}>Paper</a>,  <a href={preprintLink}>Preprint (open access)</a>
			</p>
		)
	} else if (link == null && preprintLink != null) {
		linkPart = <p><a href={preprintLink}>Preprint (open access)</a></p> 
	}
	return (
		<div className="publication">
			<p className="author">
				{authors}
			</p>
			<p className="publicationTitle">
				{title}
			</p>
			<p className="journal">
				{journal}
			</p>
			{linkPart}
		</div>
	)
};

Publication.propTypes = {
	authors: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	journal: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	preprintLink: PropTypes.string
}

Publication.defaultProps = {
}

export default Publication
